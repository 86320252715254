import order from "./order";
import system from "./system";
const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "layouts" */ "@/views/index.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "layouts" */ "@/views/home.vue"),
        meta: {
          isTab: true,
          keepAlive: false,
          requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
          links: ["首页"]
        }
      },
      ...order,
      ...system
    ] //二级子路由
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "user" */ "@/views/user/login")
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/register")
  },
  {
    path: "/403",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "exception" */ "@/views/exception/403")
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "exception" */ "@/views/exception/404")
  }
];

export { routes };
