import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { routes } from "./home.js"; //整体的路由数组

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});

//全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    NProgress.start();
  }

  //没有登录跳登录页面,目前是存session里面的
  if (to.matched.some(res => res.meta.requireAuth)) {
    // 验证是否需要登陆
    if (sessionStorage.getItem("token")) {
      // 查询本地存储信息是否已经登陆
      next();
    } else {
      next("/login"); // 未登录则跳转至login页面\);
    }
  } else {
    console.log("to1111", to);
    if (to.name == "login") {
      if (sessionStorage.getItem("token")) {
        next("/home");
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

//解决路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export default router;
