export default [
  {
    path: "/package_list",
    name: "packageManage",
    meta: {
      isTab: true,
      keepAlive: true,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["包裹管理"]
    },
    component: () =>
      import(
        /* webpackChunkName: "domestic" */ "@/views/domestic/packageManage.vue"
      )
  },
  {
    path: "/package_add",
    name: "packagePrediction",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["代客预报"]
    },
    component: () =>
      import(
        /* webpackChunkName: "domestic" */ "@/views/domestic/packagePrediction.vue"
      )
  },
  {
    path: "/sign_storage",
    name: "signStorage",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["签收入库"]
    },
    component: () =>
      import(
        /* webpackChunkName: "domestic" */ "@/views/domestic/signStorage.vue"
      )
  },
  {
    path: "/on_shelf",
    name: "onShelf",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["上架"]
    },
    component: () =>
      import(/* webpackChunkName: "domestic" */ "@/views/domestic/onShelf.vue")
  },

  {
    path: "/ordergrid_search",
    name: "packageSearch",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["查询包裹"]
    },
    component: () =>
      import(
        /* webpackChunkName: "domestic" */ "@/views/domestic/packageSearch.vue"
      )
  },

  {
    path: "/order_list",
    name: "orderList",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["转运单"]
    },
    component: () =>
      import(/* webpackChunkName: "abroad" */ "@/views/abroad/orderList.vue")
  },
  {
    path: "/group_list",
    name: "groupList",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["拼团"]
    },
    component: () =>
      import(/* webpackChunkName: "abroad" */ "@/views/abroad/groupList.vue")
  },
  {
    path: "/add_deliver",
    name: "addOrder",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["新增转运单"]
    },
    component: () =>
      import(/* webpackChunkName: "abroad" */ "@/views/abroad/addOrder.vue")
  }
];
