import Vue from "vue";
import Vuex from "vuex";
import layouts from "./layouts";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: ""
  },
  mutations: {
    getUserInfo(state) {
      let userInfo = localStorage.getItem("user");
      if (userInfo != null && userInfo != undefined) {
        let user = JSON.parse(userInfo);
        state.userInfo = user;
      }
    }
  },
  actions: {},
  modules: {
    layouts
  }
});
