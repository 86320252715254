export default {
  showLocalVoice: function (this_, voice) {
    let audioElement = document.getElementById('showVoice_audio');
    if (audioElement == null || audioElement == undefined) {
      let audio = document.createElement("audio");
      audio.id = "showVoice_audio";
      audio.style = "display:none";
      audio.autoplay = "";
      audio.controls = "";
      audio.src = "";
      let head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;
      head.insertBefore(audio, head.firstChild);
      audioElement = document.getElementById('showVoice_audio');
    }
    audioElement.setAttribute('src', 'http://upload.wuliuyerp.com/' + voice + '.mp3');

    audioElement.play();
  },
  formatTime: function () {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    return [year, month, day].map(this.formatNumber).join('-') + ' ' + [hour, minute, second].map(this.formatNumber).join(':')
  },
  formatNumber: function (n) {
    n = n.toString()
    return n[1] ? n : '0' + n
  }
}
