import { showAddRole } from "@/api/index";
// 页面布局数据以及菜单数据管理
const state = {
  menuData: [],
  isCollapse: false
};

const mutations = {
  isCollapseChange(state) {
    state.isCollapse = !state.isCollapse;
  },

  setMenuData(state, data) {
    state.menuData = data;
  }
};

const actions = {
  toggleSideBar: ({ commit }) => {
    commit("isCollapseChange");
  },
  getMenuData: async ({ commit }) => {
    let res = await showAddRole();
    if (res.ack == 200) {
      console.log(res);
      let menuData = res.data.rmList;
      commit("setMenuData", menuData);
      return "success";
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
