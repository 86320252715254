import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import filter from "./utils/filter.js";
// 引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
Object.keys(filter).forEach(key => Vue.filter(key, filter[key]));

Vue.use(ElementUI, { size: "medium", zIndex: 3000 });
Vue.config.productionTip = false;
Vue.prototype.imgSrc = "http://upload.wuliuyerp.com/";

import _ from "lodash";
Vue.prototype._ = _;

import globals from "../public/global.js";
Vue.prototype.$globals = globals;

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

//vue 初始化之前先根据登录状态拿权限数据
import { Loading } from "element-ui";
let token = sessionStorage.getItem("token");
if (token) {
  let loadingInstance = Loading.service({
    lock: true,
    text: "loading...",
    background: "rgba(225,225,225,0.2)",
    spinner: "el-icon-loading"
  });
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
  loadingInstance.close();
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
}
