export default [
  {
    path: "/employe_list",
    name: "employe",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["员工管理"]
    },
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/views/system/employe/index.vue"
      )
  },
  {
    path: "/role_list",
    name: "role",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["权限管理"]
    },
    component: () =>
      import(/* webpackChunkName: "system" */ "@/views/system/role/index.vue")
  },
  {
    path: "/logistics_list",
    name: "logistic",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["物流管理"]
    },
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/views/system/logistics/index.vue"
      )
  },
  {
    path: "/warehouse",
    name: "warehouse",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["仓库管理"]
    },
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/views/system/warehouse/index.vue"
      )
  },
  {
    path: "/custom",
    name: "custom",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["客户管理"]
    },
    component: () =>
      import(/* webpackChunkName: "system" */ "@/views/system/custom/index.vue")
  },
  {
    path: "/contraband",
    name: "contraband",
    meta: {
      isTab: true,
      keepAlive: false,
      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
      links: ["禁运管理"]
    },
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/views/system/contraband/index.vue"
      )
  }
];
