import { post } from "../utils/http.js";
//注册
export const register = data => post("api/extra/registCompany", data);
//登录
export const login = data => post("api/extra/login", data);

//国内包裹
//包裹管理-查询包裹
export const queryPackage = data => post("api/findPackage", data);
//查询包裹信息
export const packageInfor = data => post("api/findSignPackage", data);
//查看日志
export const checkLog = data => post("api/findPackageLog", data);
//新增包裹
export const addPackage = data => post("api/addPackage", data);
//修改包裹
export const updatePackage = data => post("api/updatePackage", data);
//查询客户信息
export const customInfor = data => post("api/findCustom", data);
//代客预报
export const addPackageMulti = data => post("api/addPackageMulti", data);
//包裹签收
export const signPackage = data => post("api/signPackage", data);
//扫描仓位
export const scan = data => post("api/findWarehouseGridCount", data);
//上架
export const putShelves = data => post("api/updatePackageOnshelf", data);

//海外包裹
//转运订单
export const DeliverOrder = data => post("api/findDeliverOrder", data);
//新增转运订单
export const addDeliverByPc = data => post("api/addDeliverByPc", data);
//设置付款信息
export const payOrder = data => post("api/editOrderAndPlus", data);
//获取订单信息
export const getOrderInfo = data => post("api/findTbDeliverPlusSingle", data);
//修改转运订单
export const replyDeliver = data => post("api/editOrderAndPlus", data);
//转运订单日志
export const checkdeliverLog = data => post("api/findDeliverLog", data);
//拼团
export const groupList = data => post("api/findGroupOrder", data);
//修改订单信息
export const updateGroup = data => post("api/updateGroupOrder", data);
//新增拼团
export const addGroup = data => post("api/saveGroupOrder", data);
//查询国家列表
export const queryCountry = data => post("api/findCountryCode", data);
//开始打包
export const startPackageGroup = data => post("api/startPackageGroup", data);
//结束打包
export const finishPackageGroup = data => post("api/finishPackageGroup", data);
//发货
export const shipedGroupPackage = data => post("api/shipedGroupPackage", data);

//客户列表
export const customList = data => post("api/findCustom", data);
//修改客户
export const updateCustom = data => post("api/updateCustom", data);
//新增客户
export const addCustom = data => post("api/addCustom", data);
//作废
export const updatePackageStateToCancel = data =>
  post("api/updatePackageStateToCancel", data);
//取消作废
export const revertPackageState = data => post("api/revertPackageState", data);

//查询物流
export const findLogistics = data => post("api/findLogistics", data);
//修改物流
export const updateLogistics = data => post("api/updateLogistics", data);
//新增物流
export const addLogistics = data => post("api/saveOrUpdateLogisticsInfo", data);
//查询物流详情
export const findLogisticsInfo = data => post("api/findLogisticsInfo", data);
//修改物流状态
export const editLogisticsState = data => post("api/editLogisticsState", data);

//员工列表
export const employeeList = data => post("api/findEmployee", data);
//新增员工
export const addEmployee = data => post("api/addEmployee", data);
//修改员工
export const updateEmployee = data => post("api/editEmployee", data);
//修改员工状态
export const updateEmployeeState = data =>
  post("api/updateEmployeeState", data);
//员工角色列表
export const roleList = data => post("api/findShowRole", data);
//上传文件
export const upLoadFile = data => post("api/upLoadFile", data);
//密码初始化
export const initPwd = data => post("api/initPwd", data);

//仓库列表
export const findTbWarehouse = data => post("api/findTbWarehouse", data);
//新增苍鹭
export const addWarehouse = data => post("api/addWarehouse", data);
//修改苍鹭
export const updateWarehouse = data => post("api/updateWarehouse", data);
//获取仓位
export const findWarehouseGrid = data => post("api/findWarehouseGrid", data);
//新增仓位
export const addWarehouseGrid = data => post("api/addWarehouseGrid", data);
//删除仓位
export const delGrid = data => post("api/deleteWarehouseGrid", data);
//获取城市
export const findChinaCity = data => post("api/findChinaCity", data);

//权限管理
//菜单列表
export const showAddRole = data => post("api/showAddRole", data);
//权限人物列表
export const findTbRole = data => post("api/findTbRole", data);
//新增角色
export const addTbRole = data => post("api/addTbRole", data);
//修改状态
export const updateTbRoleState = data => post("api/updateTbRoleState", data);
//修改角色
export const updateTbRole = data => post("api/updateTbRole", data);

//禁运管理列表
export const findContraband = data => post("api/findContraband", data);
//删除禁运
export const deleteContraband = data => post("api/deleteContraband", data);
//新增禁运
export const saveContraband = data => post("api/saveContraband", data);
//修改禁运
export const updateContraband = data => post("api/updateContraband", data);

//客户海外地址管理
export const findReceivePlusApi = data => post("api/findReceivePlus", data);
